// Font Family
$frank: 'Karla', serif;
$lato: 'Karla', sans-serif;

// Colors ---------------
$white: #ffffff;

$black: #1f1841;
$black-2: #1b133f;
$black-3: #423d7c;
$black-4: #2c234d;
$green: #25ad0a;
$green-2: #8aa29a;
$green-3: #e9f7e6;
$green-4: #28ba85;
$blue: #0b0385;
$blue-2: #5749ff;
$purple: #7b23de;
$pink: #ff49ec;
$or: #fd914f;


$grey: #fafafa;
$grey-2: #f8f7fe;
$grey-3: #d4d3eb;
$grey-4: #ecebff;
$grey-5: #cac9d3;
$grey-6: #9895b4;
$grey-7: #bcbbcb;
$grey-8: #f4f5fd;
$grey-9: #f5f5f5;
$grey-10: #f1f1f1;
$grey-11: #bbb;
$grey-12: #f8f7fd;
$grey-13: #eae9f4;
$grey-14: #dadde3;
$grey-15: #f3f1fa;
$grey-16: #f3f2f8;
$grey-17: #f1edf8;
$grey-18: #f8f8f8;
$grey-19: #f9f9f9;
$grey-20: #9b9aa4;
$grey-21: #f7f7f7;

$theme-color: #4636ff;
$body-text-color: #686777;


//border colors
$border: #f4f4f4;
$border-2: #5323ca;
$border-3: #9340df;
$border-4: #4a39b6;
$border-5: #ad40fc;
$border-6: #e2e0eb;
$border-7: #e7e7e7;
$border-8: #dddae9;
$border-9: #eeeeee;
$border-10: #f6f6f8;
$border-11: #dbdae0;
$border-12: #e8e7ec;
$border-13: #f1f0f7;

// Heading Color
$heading-color: #1f1841;

// Soft color
$black-soft: #777689;
$black-soft-2: #626082;
$black-soft-3: #4a4960;
$black-soft-4: #646374;
$black-soft-5: #8c8b9c;
$black-soft-6: #8f8f9a;

// Responsive Variables
$laptop: 'only screen and (min-width: 1200px) and (max-width: 1600px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';
$xs: '(max-width: 575px)';