/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

/* 1. Theme default css */
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');
// @import url('../css/gramatikaFonts.css');

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	body {
		font-family: $lato;
		font-size: 16px;
		font-weight: normal;
		color: $body-text-color;
		line-height: 24px;
		overflow-x: hidden;
	}

	.w-img {
		& img {
			width: 100%;
		}
	}

	.m-img {
		& img {
			max-width: 100%;
		}
	}

	a,
	.btn,
	button,
	span,
	p,
	i,
	input,
	select,
	textarea,
	li,
	img,
	*::after,
	*::before,
	.transition-3,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@include transition(.3s);
	}

	a:focus,
	.button:focus {
		text-decoration: none;
		outline: none;
	}

	a:focus,
	a:hover {
		color: inherit;
		color: #3D1F77;
		text-decoration: none;
	}

	a,
	button {
		color: inherit;
		outline: none;
		border: none;
		text-decoration: none;
	}

	button:hover {
		cursor: pointer;
	}

	button:focus {
		outline: 0;
		border: 0
	}

	.uppercase {
		text-transform: uppercase;
	}

	.capitalize {
		text-transform: capitalize;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $frank;
		color: $heading-color;
		margin-top: 0px;
		font-weight: 700;
		line-height: 1.2;
		@include transition(.3s);
	}

	h1 {
		font-size: 40px;
	}

	h2 {
		font-size: 36px;
	}

	h3 {
		font-size: 27px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 16px;
	}

	h6 {
		font-size: 14px;
	}

	ul {
		margin: 0px;
		padding: 0px;
	}

	ol {
		margin: 0;
		padding: 0;
	}

	li {
		list-style: none;
		color: rgb(255, 255, 255);
	}

	:hover {
		color: rgb(161, 156, 184);
	}

	p {
		font-family: $lato;
		font-size: 20px;
		font-weight: normal;
		color: $body-text-color;
		margin-bottom: 15px;
		line-height: 24px;
	}

	*::-moz-selection {
		background: $theme-color;
		color: $white;
		text-shadow: none;
	}

	::-moz-selection {
		background: $theme-color;
		color: $white;
		text-shadow: none;
	}

	::selection {
		background: $theme-color;
		color: $white;
		text-shadow: none;
	}

	/*--
    - Input Placeholder
-----------------------------------------*/
	*::-moz-placeholder {
		color: $black;
		font-size: 14px;
		opacity: 1;
	}

	*::placeholder {
		color: $black;
		font-size: 14px;
		opacity: 1;
	}


	/*--
    - Common Classes
-----------------------------------------*/
	.fix {
		overflow: hidden
	}

	.clear {
		clear: both;
	}

	.f-left {
		float: left
	}

	.f-right {
		float: right
	}

	.p-relative {
		position: relative;
	}

	.p-absolute {
		position: absolute;
	}

	/*--
    - Background color
-----------------------------------------*/
	.grey-bg {
		background: $grey;
	}

	.grey-bg-12 {
		background: $grey-12;
	}

	.grey-bg-15 {
		background: $grey-15;
	}

	.grey-bg-16 {
		background: $grey-16;
	}

	.grey-bg-18 {
		background: $grey-18;
	}

	.grey-bg-19 {
		background: $grey-19;
	}

	.theme-bg {
		background: $theme-color;
	}

	.white-bg {
		background: $white;
	}

	.black-bg {
		background: $black;
	}

	.black-bg-2 {
		background: $black-2;
	}

	.gradient-bg {
		background-image: -moz-linear-gradient(40deg, rgb(70, 54, 255) 0%, rgb(137, 35, 255) 100%);
		background-image: -webkit-linear-gradient(40deg, rgb(70, 54, 255) 0%, rgb(137, 35, 255) 100%);
		background-image: -ms-linear-gradient(40deg, rgb(70, 54, 255) 0%, rgb(137, 35, 255) 100%);
		background-image: linear-gradient(40deg, rgb(70, 54, 255) 0%, rgb(137, 35, 255) 100%);
	}


	// .primary-bg {
	// 	background: #222;
	// }

	/*--
    - color
-----------------------------------------*/

	.text-white h1,
	.text-white h2,
	.text-white h3,
	.text-white h4,
	.text-white h5,
	.text-white h6,
	.text-white p,
	.text-white span,
	.text-white li,
	.text-white a {
		color: $white !important;
	}

	.theme-color h1,
	.theme-color h2,
	.theme-color h3,
	.theme-color h4,
	.theme-color h5,
	.theme-color h6,
	.theme-color p,
	.theme-color span,
	.theme-color li,
	.theme-color a {
		color: $theme-color !important;
	}

	.white-color {
		color: $white !important;
	}


	.black-color {
		color: $black;
	}

	.blue-2-color {
		color: $blue-2;
	}

	.pink-color {
		color: $pink;
	}

	.green-4-color {
		color: $green-4;
	}

	.orange-color {
		color: $or;
	}


	// others common css here :)

	/* custom col */

	.custom-col-2 {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}

	/* spacing */
	.mt--100 {
		margin-top: -100px;

		@media #{$xs} {
			margin-top: 0;
		}
	}

	.mt--120 {
		margin-top: -120px;
	}

	.pt-270 {
		padding-top: 270px;
	}

	/* btn */

	.z-btn {
		display: inline-block;
		height: 54px;
		line-height: 48px;
		border: 2px solid $theme-color;
		background: $theme-color;
		color: $white;
		padding: 0 30px;
		@include border-radius(10px);
		font-weight: 700;
		text-transform: capitalize;
		min-width: 160px;
		text-align: center;

		& i {
			margin-left: 8px;
		}

		&:hover {
			background: $white;
			color: $theme-color;
		}

		&-white {
			background: $white;
			border-color: $white;
			color: $black;

			&:hover {
				background: $black;
				border-color: $black;
				color: $white;
			}

			&-2 {
				font-size: 15px;
				height: 44px;
				line-height: 40px;
			}

			&-3 {
				&:hover {
					background: $theme-color;
					border-color: $theme-color;
				}
			}
		}

		&-transparent {
			background: transparent;
			border-color: rgba($color: $white, $alpha: .2);

			&:hover {
				background: $white;
				color: $black;
				border-color: $white;
			}

			&-2 {
				border-color: rgba($color: $white, $alpha: .2);
			}
		}

		&-border {
			border-color: $border-6;
			color: $black;
			background: transparent;

			&:hover {
				background: $theme-color;
				color: $white;
				border-color: $theme-color;
			}
		}

		&-3 {
			height: 44px;
			line-height: 40px;
		}
	}

	/* link btn */

	.link-btn {
		position: relative;
		font-size: 30px;
		font-weight: 500;
		color: $black;
		text-transform: uppercase;
		padding-right: 25px;

		& i {
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%));
			transition: all ease .2s;
			-webkit-transition: all ease .2s;
			-moz-transition: all ease .2s;
			-ms-transition: all ease .2s;
			-o-transition: all ease .2s;

			&:first-child {
				left: -100%;
				visibility: hidden;
				opacity: 0;

			}

			&:last-child {
				right: 0;
			}
		}

		&:hover {
			color: $white;

			& i:first-child {
				left: 0;
				visibility: visible;
				opacity: 1;
			}

			& i:last-child {
				right: -100%;
				visibility: hidden;
				opacity: 0;
			}
		}
	}

	.link-btn-2 {
		position: relative;
		font-size: 15px;
		color: $black-soft-4;
		font-weight: 700;
		padding-right: 24px;
		display: inline-block;

		& i {
			position: absolute;
			top: 55%;
			margin-top: -3px;
			@include transform(translateY(-50%));
			transition: all ease .2s;
			-webkit-transition: all ease .2s;
			-moz-transition: all ease .2s;
			-ms-transition: all ease .2s;
			-o-transition: all ease .2s;
			font-size: 18px;

			&:first-child {
				right: 10%;
				visibility: hidden;
				opacity: 0;
			}

			&:last-child {
				right: 0;
			}
		}

		&:hover {
			color: $theme-color;

			& i {
				&:first-child {
					right: 0;
					visibility: visible;
					opacity: 1;
				}

				&:last-child {
					right: -10%;
					visibility: hidden;
					opacity: 0;
				}
			}
		}

		&.link-btn-3 {
			padding-right: 0;
			padding-left: 24px;
			color: $black;

			& i {
				&:first-child {
					left: 10%;
					right: auto;
				}

				&:last-child {
					left: 0;
					right: auto;
				}
			}

			&:hover {
				color: $theme-color;

				& i {
					&:first-child {
						left: 0;
					}

					&:last-child {
						left: -10%;
					}
				}
			}
		}
	}


	/* section title */

	.section {
		&__title {
			& span {
				font-size: 18px;
				color: $theme-color;
				display: inline-block;
				font-weight: 700;
				font-family: $frank;
				margin-bottom: 25px;
			}

			& h2 {
				font-size: 70px;
				font-weight: 700;
				line-height: 1.1;

				@media #{$sm} {
					font-size: 50px;
				}

				@media #{$xs} {
					font-size: 50px;
				}
			}

			&-2 {
				& h2 {
					font-size: 60px;

					@media #{$sm} {
						font-size: 50px;
					}

					@media #{$xs} {
						font-size: 50px;
					}
				}
			}

			&-3 {
				& span {
					margin-bottom: 15px;
				}

				& h2 {
					font-size: 50px;

					@media #{$md} {
						font-size: 40px;
					}

					@media #{$sm} {
						font-size: 40px;
					}

					@media #{$xs} {
						font-size: 35px;
					}
				}
			}

			&-h2 {
				& span {
					margin-bottom: 20px;
				}

			}
		}
	}

	/* scroll to top */
	#scroll a {
		position: fixed;
		right: 30px;
		bottom: 30px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 15px;
		color: $white;
		list-style-type: none;
		background: $theme-color;
		margin-right: 20px;
		border: 1px solid $theme-color;
		z-index: 999;
	}

	/* theme social */

	.theme-social {
		& ul {
			& li {
				& a {
					position: relative;
					overflow: hidden;

					&:hover {
						& i:first-child {
							left: -120%;
						}

						& i:last-child {
							left: 50%;
						}
					}

					& i {
						position: absolute;
						top: 50%;
						left: 50%;
						@include transform(translate(-50%, -50%));
						@include transition(.2s);

						&:last-child {
							left: 120%;
						}
					}
				}
			}
		}
	}


	/* basic pagination */

	.basic-pagination {
		& ul {
			& li {
				display: inline-block;
				margin-right: 10px;

				&.active {
					& a {
						color: $white;
						background: $theme-color;
					}
				}

				& a {
					position: relative;
					overflow: hidden;
					background: $grey-10;
					color: $black;
					font-size: 20px;
					display: inline-block;
					width: 44px;
					height: 44px;
					line-height: 44px;
					text-align: center;
					@include border-radius(10px);

					&:hover {
						background: $theme-color;
						color: $white;

						& i:first-child,
						& span:first-child {
							left: -130%;
						}

						& i:last-child,
						& span:last-child {
							left: 50%;
						}
					}

					& i,
					& span {
						position: absolute;
						top: 50%;
						left: 50%;
						@include transform(translate(-50%, -50%));
						@include transition(.2s);

						&:last-child {
							left: 130%;
						}
					}
				}
			}
		}
	}