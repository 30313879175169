/*----------------------------------------*/
/*  19. PROJECT CSS START
/*----------------------------------------*/

.project {
    &__area {}

    &__item {
        padding: 60px;
        height: 750px;
        @include border-radius(10px);

        @media #{$md} {
            padding: 40px;
        }

        @media #{$xs} {
            padding: 30px;
        }

        &:hover {
            & .project__no {
                & h5 {
                    background: $theme-color;
                    color: $white;
                }
            }
        }
    }

    &__no {
        & h5 {
            display: inline-block;
            font-size: 26px;
            color: $theme-color;
            height: 45px;
            width: 45px;
            line-height: 45px;
            text-align: center;
            background: rgba($color: $theme-color, $alpha: .1);
            @include border-radius(50%);
            margin-bottom: 25px;
        }
    }

    &__content {
        & h3 {
            font-size: 26px;
            font-weight: 900;
            margin-bottom: 15px;

            & a {
                &:hover {
                    color: $theme-color;
                }
            }
        }

        & img {
            max-width: 100% !important;
            height: 400px;

            @media #{$sm} {
                margin-top: 60px;
                width: 300px;
                height: auto;
            }

            @media #{$xs} {
                margin-top: 40px;
                width: 250px;
                height: auto;
            }
        }

        &__headline {
            & img {
                align-self: center;
                margin-bottom: 50px;
                width: 350px;
                height: 220px;

                @media #{$md} {
                    width: 300px;
                    height: 200px;
                }

                @media #{$sm} {
                    width: 250px;
                    height: 170px;
                }

                @media #{$xs} {
                    width: 220px;
                    height: 150px;
                }
            }
        }
    }


    &__thumb {
        & img {
            width: 400px !important;
            max-width: 100% !important;
            height: 200px;

            @media #{$sm} {
                width: 200px;
                height: auto;
            }

            @media #{$xs} {
                width: 200px;
                height: auto;
            }
        }
    }
}

.project__slider .slick-slide {
    padding-right: 30px;
}