/*----------------------------------------*/
/*  06. CAPABILITIES CSS START
/*----------------------------------------*/
.capabilities {
    &__content {
        @media #{$md} {
            position: relative;
            ;
        }

        @media #{$sm} {
            position: relative;
            ;
        }

        @media #{$xs} {
            position: relative;
            ;
        }
    }

    &__thumb {
        margin-left: 30%;
        width: 450px;
        height: 100%;
        @include background();

        @media #{$md} {
            margin-left: 0%;
        }

        @media #{$sm} {
            margin-left: 0%;
            width: 400px;
            margin-top: 30px;
        }

        @media #{$xs} {
            margin-left: 0;
            width: 350px;
            margin-top: 30px;
        }
    }

    &__shape {
        left: 0;
        top: -165px;
    }

    &__list {
        &h2 {
            text-align: 'start';
            font-size: '28px';
            width: '700px';

            @media #{$md} {
                font-size: '16px';
                width: '600px';
            }

            @media #{$sm} {
                font-size: '14px';
                width: '400px';
            }

            @media #{$xs} {
                font-size: '12px';
                width: '300px';
            }
        }

        &p {
            text-align: 'start';
            font-size: '20px';
            width: '700px';

            @media #{$md} {
                font-size: '16px';
                width: '600px';
            }

            @media #{$sm} {
                font-size: '14px';
                width: '400px';
            }

            @media #{$xs} {
                font-size: '12px';
                width: '300px';
            }
        }
    }
}