.contact {
    &__area {
      padding: 50px 0;
    }
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background: $white;
      padding: 40px;
      box-shadow: 0px 30px 50px rgba(10, 0, 58, 0.233);
      @media (max-width: 768px) {
        flex-direction: column;
        padding: 20px;
      }
    }
    &__info {
      flex: 1;
      margin-right: 20px;
      @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 36px;
        font-weight: 900;
        margin-bottom: 20px;
      }
      ul {
        list-style: none;
        padding: 0;
        li {
          margin-bottom: 20px;
          h4 {
            font-size: 16px;
            font-weight: 700;
          }
          p {
            margin: 0;
            a {
              color: inherit;
              &:hover {
                color: $theme-color;
              }
            }
          }
        }
      }
    }
    &__form {
      flex: 1;
      display: flex;
      flex-direction: column;
      input,
      textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        border: 2px solid transparent;
        border-radius: 10px;
        background: $grey-9;
        &:focus {
          border-color: $theme-color;
          background: $white;
          outline: none;
        }
      }
      textarea {
        resize: none;
        height: 120px;
      }
      .z-btn {
        padding: 2px 20px;
        background-color: $theme-color;
        color: $white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        align-self: flex-start; 
        margin-top: 10px;
        &:hover {
          background-color: darken($theme-color, 10%);
        }
        @media (max-width: 768px) {
          align-self: center; 
        }
      }
    }
  }
  